export const runEvent = {
  id: '',
  topLine: 'Event Registration Options',
  option1: '1',
  option1Desc: '',
  option2: '2',
  option2Desc: '2 description',
  option3: '3',
  option3Desc: '3 description longer than 2',
  buttonLabel: 'Register',
  route: '/eventRegistration',
  img1: require('../../images/love.svg').default,
  img2: require('../../images/this.svg').default,
  img3: require('../../images/something.svg').default
}

export const standardDonation = {
  id: '',
  topLine: 'Our Donation Options',
  option1: 'Supporter',
  option1Desc: '',
  option2: 'Benefactor',
  option2Desc: 'Benefactor tier benefits',
  option3: 'Bri\'s List',
  option3Desc: 'Make the change Bri wanted to make',
  buttonLabel: 'Donate',
  route: '/donate',
  img1: require('../../images/love.svg').default,
  img2: require('../../images/this.svg').default,
  img3: require('../../images/something.svg').default
}
