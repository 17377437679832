import styled from 'styled-components';

export const ServiceCardWrap = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
  opacity: ${({ isSelected }) => (isSelected ? '100%' : '50%')};

  &:hover {
    transform: scale(${({ isSelected }) => (isSelected ? 1.06 : 1.06 )});
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`

export const ServiceIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`

export const ServiceH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`

export const ServiceP = styled.p`
  font-size: 1rem;
  text-align: center;
`
