import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import SigninPage from './pages/signin';
import SignupPage from './pages/signup';
import DonationPage from './pages/DonationPage';
import BriStoryPage from './pages/BriStoryPage';
import AboutPage from './pages/AboutPage';
import LeadershipPage from './pages/LeadershipPage';
import RunPage from './pages/RunPage';
import BenefactorPage from './pages/BenefactorPage';
import ShopPage from './pages/ShopPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/donate" component={DonationPage} exact />
        <Route path="/brisStory" component={BriStoryPage} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/leadership" component={LeadershipPage} exact />
        <Route path="/benefactor" component={BenefactorPage} exact />
        <Route path="/shop" component={ShopPage} exact />
        <Route path="/success" component={Home} exact />
      </Switch>
    </Router>
  );
}

export default App;
