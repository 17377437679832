import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';
import { Link } from 'react-router-dom';

export const DoJiggyContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 400px;
  position: relative;
  z-index: 1;
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: background: linear-gradient(
      180deg,
      rgba(0,0,0,0.1) 0%,
      rgba(0,0,0,0.3) 100%),
      linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 2;

    background-image : linear-gradient(
                      180deg,
                      rgba(255,207,235 ,1),
                      rgba(255, 255 , 255,1 ),
                      rgba(255,255,255, 1),
                      rgba(255,207,235, 1) 100%),
                      linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);

  }
`

export const DoJiggyContent = styled.div`
  margin-top: 76px;
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
