import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import Img1 from '../images/rfb1.jpeg';
import Img2 from '../images/rfb2.jpeg';
import Img3 from '../images/rfb3.jpeg';
import Img4 from '../images/rfb4.jpeg';
import styled from 'styled-components';
import DoJiggy from '../components/DoJiggy';


const ImgContainer = styled.div`
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`

const DonationPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>

    <link href="https://go.dojiggy.io/Content/widgets/campaign-fundraising-widget.css" rel="stylesheet"/>

      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ImgContainer>
        <ImgBg src={Img1}/>
      </ImgContainer>
      <ImgContainer>
        <ImgBg src={Img2}/>
      </ImgContainer>
      <ImgContainer>
        <ImgBg src={Img3}/>
      </ImgContainer>
      <ImgContainer>
        <ImgBg src={Img4}/>
      </ImgContainer>



      <DoJiggy />

      <Footer />


    </>
  );
}

export default DonationPage
