import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const SidebarContainer = styled.aside`
  position: fixed;
  background: #fff;
  z-index: 7;
  width: 100%;
  height: 100%;
  background-image : linear-gradient(
    180deg,
    rgba(255,207,235, 1),
    rgba(255,207,235, .6),
    rgba(255,255,255, 0),
    rgba(255,207,235, 1) 100%),
    linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
  display: grid;
  align-items: center;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};


  &:after {
    content: '';
    position: absolute;
    bottom: -4px; // Adjust as needed
    left: 0;
    right: 0;
    height: 4px; // Adjust the thickness of the bar
    background-color: white;
    transform: scaleX(${({ scrollNav }) => (scrollNav ? 1 : 0)});
    transform-origin: left;
    transition: transform 0.8s ease 0.1s; // Starts after the background transition ends
    z-index: -1;
  }
`

export const Logo = styled.img`
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 0;
  display: block;
  position: absolute;

`

export const LogoBG = styled.img`
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  display: block;
`

export const NavLogo = styled(LinkR)`
  color: #ffcfeb;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1rem;
  align-items: center;
  text-align: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  position: relative;
  display: inline-block;

  &:hover {
    color: #fff;
    transition: 0.3s ease-out
  }
`

export const CloseIcon = styled(FaTimes)`
  color: #fff;
  cursor: pointer;

`

export const Icon = styled.div`
  position: absolute;
  top: 10%;
  right: 1.5rem;
  background:transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`

export const SidebarWrapper = styled.div`
  color: #fff;
`

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
    grid-template-rows: repeat(6,60px);
  }
`

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #ffcfeb;
    transtion: 0.2s ease-in-out;
  }
`


export const SidebarHardLink = styled(LinkR)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #DC015B;
    transtion: 0.2s ease-in-out;
  }
`

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`



export const SidebarRoute = styled(LinkR)`
  border-radius: 50px;
  background: #ffcfeb;
  white-space: nowrap;
  padding: 16px 64px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`
