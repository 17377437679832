import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  NavLogo,
  Logo,
  LogoBG,
  SideBtnWrap,
  SidebarHardLink,
  SidebarRoute
} from './SidebarElements';
import {animateScroll as scroll} from 'react-scroll';


//<SidebarLink to="ourImpact" onClick={toggle}>Our Impact</SidebarLink>
//<SidebarLink to="events" onClick={toggle}>Events</SidebarLink>
//<SidebarLink to="waysToGive" onClick={toggle}>Ways To Give</SidebarLink>
const toggleHome = () => {
  scroll.scrollToTop();
};

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle} >
      <NavLogo to='/' onClick={toggleHome}>
              <Logo src={require('../../images/logopng.png').default} />
              <LogoBG src={require('../../images/circle.png').default} />

            </NavLogo>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarHardLink to="/" onClick={toggle}>Home</SidebarHardLink>
          <SidebarHardLink to="/brisStory" onClick={toggle}>OUR COCKTAIL POUCHES</SidebarHardLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="/">Home</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar
