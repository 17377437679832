import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f6f6f6;
  padding: 30px;
  border-radius: 10px;
`;

const Button = styled.button`
  background: #536dfe;
  color: #ffffff;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: "$";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2em;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 15px;
  padding-left: 25px; // To make space for the dollar sign
  font-size: 1.2em;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    border-color: #536dfe;
  }
`;


const Label = styled.label`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [amount, setAmount] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    setProcessing(true);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(`Payment failed: ${error.message}`);
      setProcessing(false);
    } else {
      // send paymentMethod.id to your server
      const response = await fetch("https://kv5xdl7ln8.execute-api.us-east-1.amazonaws.com/default/handleStripePayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: amount  // send the entered amount to the server
        })
      });

      const paymentResult = await response.json();  // assumes your server responds with JSON

      if (paymentResult.error) {
        setError(`Payment failed: ${paymentResult.error.message}`);
      } else if (paymentResult.requires_action) {
        // handle any additional steps required by the payment method, e.g. 3D Secure
      } else {
        console.log(`Payment succeeded: ${paymentMethod.id}`);
        setPaymentSucceeded(true);
      }

      setProcessing(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="donation"></Label>
      <InputWrapper>
        <Input
                id="donation"
                type="number"
                min="1"
                step="1" // Increment by 1 dollar
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter donation amount"
              />

                   </InputWrapper>
                   <CardElement />

      <Button type="submit" disabled={!stripe || processing}>
        {processing ? "Processing…" : "Pay"}
      </Button>

      {error && <ErrorMessage>{error}</ErrorMessage>}
      {paymentSucceeded && <div>Payment succeeded</div>}
    </Form>
  );
};

export default PaymentForm;
