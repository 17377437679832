import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Services from '../components/Services';
import Footer from '../components/Footer';
import InfoSection from '../components/InfoSection';
import ScrollToTop from '../components/ScrollToTop';
import {
  homeObjTwo,
  homeObjThree,
  eventObj
} from '../components/InfoSection/Data';
import BannerSection from '../components/BannerSection';
import {
  bannerObjOne,
  bannerObjTwo
} from '../components/BannerSection/Data';
import { Grid, Container, Box } from '@mui/material';


//<Services {...runEvent}/>
//<Services {...standardDonation}/>

import {
  runEvent,
  standardDonation
} from '../components/Services/Data';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <ScrollToTop />

      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
        <Box sx={{ flexGrow: 1 }}>



      <BannerSection {...bannerObjOne} />
        <InfoSection {...homeObjTwo} />

        <InfoSection {...homeObjThree} />

        <InfoSection {...eventObj} />

        <BannerSection {...bannerObjTwo} />
          <Footer />

      </Box>
    </>
  );
}

export default Home
