import React, {useState} from 'react';
import {Button} from '../ButtonElement'
import {animateScroll as scroll} from 'react-scroll';
import {
  BannerContainer,
  BannerBg,
  VideoBg,
  ImgBg,
  BannerContent,
  BannerH1,
  BannerP,
  BannerBtnWrapper,
  BannerBtnLink,
  ArrowForward,
  ArrowRight
} from './BannerElements';

const BannerSection = ({
  darkTop,
  large,
  id,
  video,
  mediaSrc,
  headline,
  description,
  haveButton,
  route,
  buttonLabel,
  fade
}) => {
  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover(!hover)
  }

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <BannerContainer darkTop = {darkTop ? 1:0 } height = {large ? 1 : 0} id={id}>
      <BannerBg>
        {video ?
        <VideoBg autoPlay loop muted playsInline src={mediaSrc} type='video/mp4'/> : <ImgBg src={mediaSrc} />
      }
      </BannerBg>
      <BannerContent>
        <BannerH1>{headline}</BannerH1>
        <BannerP>{description}</BannerP>
          {haveButton ?
          <BannerBtnWrapper>
            <BannerBtnLink
              onClick={toggleHome}
              to={route}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-40}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              >{buttonLabel}{hover ? <ArrowForward /> : <ArrowRight />}</BannerBtnLink>
          </BannerBtnWrapper> : null
        }
      </BannerContent>
    </BannerContainer>
  );
}

export default BannerSection;
