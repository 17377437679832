export const bannerObjOne = {
  darkTop: false,
  large: true,
  id: 'home',
  video: false,
  mediaSrc: require('../../images/cluster.png').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '/donate',
  buttonLabel: "Donate",
  fade: true
}

export const bannerObjTwo = {
  darkTop: false,
  large: true,
  id: 'hands',
  video: false,
  mediaSrc: require('../../images/threestock.png').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '/donate',
  buttonLabel: '',
  fade: true
}

export const bannerObjThree = {
  darkTop: false,
  large: false,
  id: 'brisStory',
  video: false,
  mediaSrc: require('../../images/varietypack.png').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '/',
  buttonLabel: 'Back To Home',
  fade: true
}

export const bannerObjFour = {
  darkTop: false,
  large: false,
  id: 'brisStory',
  video: false,
  mediaSrc: require('../../images/threestock.png').default,
  headline: '',
  description: '',
  haveButton: true,
  route: '/',
  buttonLabel: 'Back To Home',
  fade: true
}

export const bannerObjFive = {
  darkTop: false,
  large: true,
  id: 'shop',
  video: true,
  mediaSrc: require('../../videos/shop.mp4').default,
  headline: 'Shop Coming Soon',
  description: '',
  haveButton: true,
  route: '/',
  buttonLabel: 'Back To Home',
  fade: true
}


export const bannerObjSixA = {
  darkTop: false,
  large: true,
  id: 'run',
  video: false,
  mediaSrc: require('../../images/threestock.png').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '/',
  buttonLabel: 'Back To Home',
  fade: false
}

export const bannerObjSixB = {
  darkTop: false,
  large: true,
  id: 'run',
  video: false,
  mediaSrc: require('../../images/margarita.png').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '/',
  buttonLabel: 'Back To Home',
  fade: false
}

export const bannerObjSixC = {
  darkTop: false,
  large: true,
  id: 'run',
  video: false,
  mediaSrc: require('../../images/mojito.png').default,
  headline: '',
  description: '',
  haveButton: false,
  route: '/',
  buttonLabel: 'Back To Home',
  fade: false
}
