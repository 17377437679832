import React, { useState } from 'react';
import {
    GalleryWrapper,
    NavigationArrow,
    FlavorCard,
    FlavorImage,
    FlavorDescription,
    InfoContainer
} from './GalleryBookElements';


function GalleryBook() {
    const flavors = [
        { name: 'Mojito', image: require('../../images/mojito.png').default, description: 'Minty Fresh Adventure in Every Sip!' },
        { name: 'Cosmo', image: require('../../images/cosmo.png').default, description: 'Cosmopolitan Elegance in a Pouch!' },
        { name: 'Margarita', image: require('../../images/margarita.png').default, description: 'A Fiesta in Every Pouch!' },
        { name: 'Lychee Martini', image: require('../../images/lycheemartinistd.png').default, description: 'Exotic Lychee, Timeless Elegance!' },
        { name: 'Paloma', image: require('../../images/paloma.png').default, description: 'A Refreshing Twist with a Citrus Kick!' }
      ];

      console.log("Gallwwr")
  const [currentFlavorIndex, setCurrentFlavorIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentFlavorIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : flavors.length - 1));
  };

  const handleNextClick = () => {
    setCurrentFlavorIndex((prevIndex) => (prevIndex < flavors.length - 1 ? prevIndex + 1 : 0));
  };

  const getCardClassName = (index) => {
    if (index === currentFlavorIndex) return "current";
    if (index === (currentFlavorIndex - 1 + flavors.length) % flavors.length) return "previous";
    if (index === (currentFlavorIndex + 1) % flavors.length) return "next";
    return "";
  };

  return (
    <InfoContainer>

    <GalleryWrapper>
      <NavigationArrow onClick={handlePrevClick} className="left">{"<"}</NavigationArrow>
      {flavors.map((flavor, index) => (
        <FlavorCard key={flavor.name} className={getCardClassName(index)}>
          <FlavorImage src={flavor.image} alt={flavor.name} />
          <h3>{flavor.name}</h3>
          <FlavorDescription>{flavor.description}</FlavorDescription>
        </FlavorCard>
      ))}
      <NavigationArrow onClick={handleNextClick} className="right">{">"}</NavigationArrow>
    </GalleryWrapper>
    </InfoContainer>
  );
}

export default GalleryBook;