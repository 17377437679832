export const homeObjOneA = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
   buttonLabel: 'Donate',
  route: '/donate',
  imgStart: true,
  img: require('../../images/cluster.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
export const homeObjOneB = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
   buttonLabel: 'Donate',
  route: '/donate',
  imgStart: false,
  img: require('../../images/margarita.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
export const homeObjOneC = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
   buttonLabel: 'Donate',
  route: '/donate',
  imgStart: true,
  img: require('../../images/mojito.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}
export const homeObjOneD = {
  id: 'brisStory',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
   buttonLabel: 'Donate',
  route: '/donate',
  imgStart: false,
  img: require('../../images/lycheemartini.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}



export const homeObjTwo = {
  id: 'ourImpact',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
  buttonLabel: 'Donate',
  route: '/donate',
  imgStart: false,
  img: require('../../images/lmstock.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: false
}

export const homeObjThree =  {
  id: 'events',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
  buttonLabel: 'Donation Portal',
  route: '/donate',
  imgStart: true,
  img: require('../../images/palomastock.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: true
}

export const eventObj = {
  id: 'waysToGive',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '...',
  headline: '...',
  description: "...",
  buttonLabel: 'Donation Portal',
  route: '/donate',
  imgStart: false,
  img: require('../../images/threestock.png').default,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
  haveButton: true
}
