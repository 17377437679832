import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import DonateSection from '../components/DonateSection';
import {
  bannerObjFour
} from '../components/BannerSection/Data';


const DonationPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };




  return (
    <>
    <link href="https://go.dojiggy.io/Content/widgets/campaign-fundraising-widget.css" rel="stylesheet"/>


      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <DonateSection />
      <Footer />
    </>
  );
}

export default DonationPage
