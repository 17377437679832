import React, {useState, useEffect} from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentForm from '../PaymentForm';

import CardIcon from "../../images/love.svg";
import ProductImage from "../../images/bri.jpeg";
/**/
const STRIPE_PUBLISHABLE= "pk_live_51KbUZF2H3hUJ5j7uu3NcoGliK7t6X0gDJ8ZPgtLNpEb6jG0NlfOycg6LuZdW87JkOExwgC58WvykxIWrHMHl4a1L00mxmDybRk"

const stripePromise = loadStripe(STRIPE_PUBLISHABLE);

const StripeContainer = (props) => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    // handle result.error or result.paymentMethod
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Donate
      </button>
    </form>
  );
}


export default StripeContainer;
