import React, {useState} from 'react';
import {animateScroll as scroll} from 'react-scroll';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Video from '../../videos/shop.mp4';
import {Button} from '../ButtonElement'
import StripeContainer from '../StripeContainer';
import PaymentForm from '../PaymentForm/index';
import { Container } from '@mui/material';


import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  HeroBtnLink,
  ArrowForward,
  ArrowRight,
  Row
} from './YachtElements';

const STRIPE_PUBLISHABLE= "pk_live_51KbUZF2H3hUJ5j7uu3NcoGliK7t6X0gDJ8ZPgtLNpEb6jG0NlfOycg6LuZdW87JkOExwgC58WvykxIWrHMHl4a1L00mxmDybRk"

const stripePromise = loadStripe(STRIPE_PUBLISHABLE);

const HandsSection = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  const [showItem, setShowItem] = useState(false);


  const [data, setData] = useState(null);

  const getData = e => {
    setData(e.target.value);
    console.log(e.target.value);
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg autoPlay loop muted playsInline src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <Row>
      <Elements stripe={stripePromise}>
        <PaymentForm/>
      </Elements>
    </Row>
      </HeroContent>
    </HeroContainer>
  );
}

export default HandsSection;
