import styled, { keyframes } from 'styled-components';

// Animation keyframes for sliding in
export const slide = keyframes`
from {
  transform: scale(0.8);
}
to {
  transform: scale(1);
}
`;

// Styled components
export const GalleryWrapper = styled.div`
  width: fit-content;
  position: relative;
  height: 400px; // Adjust as necessary
  margin: 100px auto;
`;

export const NavigationArrow = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: 50px;
  height: 200px;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  color: #fff;
  user-select: none;
  z-index: 4; // Ensure arrows are above all cards

  &:hover {
    color: #ffafe1;
  }
  &.left {
    left: calc(50% - 150px - 20px); // Adjust the 150px based on half the width of your current card
  }

  &.right {
    right: calc(50% - 150px - 20px); // Same adjustment as the left arrow
  }

  @media screen and (max-width: 960px) { // Adjust the breakpoint as needed for mobile devices
    &.left, &.right {
      font-size: 40px; // Optional: Adjust the size for smaller screens
      // Further positioning adjustments can be added here if needed
    }
  }
`;


export const FlavorCard = styled.div`
width: 300px;
overflow: hidden;
height: 300px;
border: 1px solid #ddd;
padding: 20px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
text-align: center;
position: absolute;
transition: transform 0.5s ease, opacity 0.5s ease, z-index 0.5s;
backface-visibility: hidden;
opacity: 0.2; // Lower opacity for rear cards
  z-index: 1; // Base z-index for all cards

  &.current {
    transform: translateX(-50%) scale(1.25);
    opacity: 1; // Full opacity for the current card
    z-index: 3; // Highest z-index for the current card
  }

  &.previous, &.next {
    transform: scale(0.8);
    opacity: 0.5; // Higher opacity than rear cards
    z-index: 2; // Higher z-index than rear cards
    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  &.previous {
    transform: translateX(-150%) scale(0.8);
    
  }

  &.next {
    transform: translateX(50%) scale(0.8);
  }

  &:not(.current, .previous, .next) {
    transform: translateX(-50%) scale(0.7); // Adjust scale as needed
    opacity: 0;
  }

  background-image : linear-gradient(
                    180deg,
                    rgba(255,255,255, .8),
                    rgba(255,207,235, .6),
                    rgba(255,207,207, 0),
                    rgba(255,207,207, .4),
                    rgba(255,255,255, .4) 100%),
                    linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);

`;

export const InfoContainer = styled.div`
  color: #fff;
  background: '#f9f9f9';
  display: flex;
  justify-content: center;
  align-items: center;
  background-image : linear-gradient(
                    180deg,
                    rgba(255,207,235, 1),
                    rgba(255,207,235, .6),
                    rgba(255,255,255, 0),
                    rgba(255,207,235, 1) 100%),
                    linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const FlavorImage = styled.img`
max-width: 100%;
max-height: 80%;
margin-bottom: 10px;
`;

export const FlavorDescription = styled.p`
font-size: 0.9em;
`;